import * as serviceWorker from "./serviceWorker";
import { Provider } from 'react-redux';
import { store } from 'store/configureStore';
import "./index.scss";
import "dotenv/config";


import React from "react";
import ReactDOM from "react-dom";
import configService from "./services/ConfigService";
import App from "./App";

const getEntryNode = async () => {
  const APP_ROOT_ID = "gs-app";
  const APP_SHADOW_DOM_ROOT_CONTAINER_ID = "gs-app-shadow";
  const APP_SHADOW_DOM_STYLES_SLOT_ID = "gs-app-styles";
  // get config
  const localConfig = localStorage.getItem("config")
  const config = localConfig ? JSON.parse(localConfig) : (await configService.fetchConfig())
  const widgetConfig = Object.values(config?.widgets?.drools).find(c=> c.enableShadowDOM !== undefined)
  if (widgetConfig && widgetConfig.enableShadowDOM) {
    const shadowAppRoot = document.getElementById(APP_ROOT_ID).attachShadow({ mode: "open" });
    // styles container
    const styleSlot = document.createElement("span");
    styleSlot.id = APP_SHADOW_DOM_STYLES_SLOT_ID;
    shadowAppRoot.appendChild(styleSlot);

    // create the element where we would render our app
    const shadowAppContainer = document.createElement("div");
    shadowAppContainer.id = APP_SHADOW_DOM_ROOT_CONTAINER_ID;
    shadowAppRoot.appendChild(shadowAppContainer);
    return shadowAppContainer;
  }

  return document.getElementById(APP_ROOT_ID);
};

let count = 0;
const attemptRender = async () => {
  count++;
  try {
    const entryNode = await getEntryNode();
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      entryNode
    );

    clearInterval(renderFunction);
  } catch (error) {
    console.log("Unable to locate entry node");
    if (count >= 5) clearInterval(renderFunction);
  }
};

const renderFunction = setInterval(attemptRender, 1000);


serviceWorker.unregister();
