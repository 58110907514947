/* eslint-disable camelcase */
import axios from "axios";
import i18next from "i18next";
import {
  application,
  apiUrl,
  apigeeTranslationApiUrl,
  apigeeTranslationApiKey,
  envId,
} from "settings";
import translations from "../translations.json";
import languages from "../languages.json";
class TranslationService {
  moduleT = (module) => (key, options) =>
    i18next.t(`${module}.${key}`, options);
  fetchTranslations = async (language) => {
    const headers = apigeeTranslationApiUrl
      ? {
          "content-type": "application/json",
          Authorization: `Basic ${apigeeTranslationApiKey}`,
        }
      : {
          "content-type": "application/json",
        };
    const url = apigeeTranslationApiUrl
      ? `${apigeeTranslationApiUrl}/${
          window.REACT_APP_ENV_ID || envId
        }/${application}/${language}`
      : `${apiUrl}/public/v1/languages/${
          window.REACT_APP_ENV_ID || envId
        }/${application}/${language}`;
    // const { data } = await axios.create()({
    //   method: 'get',
    //   url,
    //   headers
    // });

    return { data: translations[language] };
  };
  fetchLanguages = async () => {
    const headers = apigeeTranslationApiUrl
      ? {
          "content-type": "application/json",
          Authorization: `Basic ${apigeeTranslationApiKey}`,
        }
      : {
          "content-type": "application/json",
        };
    const url = apigeeTranslationApiUrl
      ? `${apigeeTranslationApiUrl}/${
          window.REACT_APP_ENV_ID || envId
        }/${application}`
      : `${apiUrl}/public/v1/languages/${
          window.REACT_APP_ENV_ID || envId
        }/${application}`;
    try {
      // const data = await axios.create()({
      //   method: 'get',
      //   url,
      //   headers
      // });
      return languages;
    } catch (err) {
      return console.log("Error in translation service:", err);
    }
  };
}

const inst = new TranslationService();
export default inst;
